import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Flex,
  Button,
} from "@tremor/react";
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import './UserListComponent.css';
import { Link } from "react-router-dom";
import { User } from '../../interfaces/AccessInterfaces';
import axios from 'axios';
import { useCookies } from 'react-cookie';

export default function UserListComponent() {
    const [cookies] = useCookies(['mazelog-user']);
    const token = cookies['mazelog-user']?.token_data?.access_token;
    const [users, setUsers] = useState<User[]>([]);
    const [requested, setRequested] = useState<boolean>(false);

    const handleSubmit = () => {
        const baseURL = process.env.REACT_APP_MAZELOG_AUTH_BASE_URL;
        const oauth = "Bearer " + token;
        axios.get(`${baseURL}/users`,
          {headers: {accept: 'application/json', "Authorization": oauth}}
        )
        .then((res)=>{
          console.log(res);
          setUsers(res.data.data);
        })
        .catch((err)=>{
          console.log(err);
        })
    }

    useEffect(()=>{
        if (!requested){
          handleSubmit();
          setRequested(true);
        }
      },[requested])

  return (
    <div>
        <div className='ButtonBlock'>
            <Flex justifyContent='end'>
                <Link to={`/users/create`}>
                    <Button className='DownloadBtn'>Criar usuário</Button>
                </Link>
            </Flex>
        </div>
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>ID usuário</TableHeaderCell>
                    <TableHeaderCell className="text-left">ID organização</TableHeaderCell>
                    <TableHeaderCell className="text-left">Nome de usuário</TableHeaderCell>
                    <TableHeaderCell className="text-left">Email</TableHeaderCell>
                    <TableHeaderCell className="text-left">Perfil</TableHeaderCell>
                    <TableHeaderCell className="text-left">Ativo</TableHeaderCell>
                </TableRow>
            </TableHead>

            <TableBody>
                { users
                    .map((user) => (
                        <TableRow key={ user.id_user }>
                            <TableCell className="TableField">{ user.id_user }</TableCell>
                            <TableCell className="TableField">{ user.id_org }</TableCell>
                            <TableCell className="TableField">{ user.username }</TableCell>
                            <TableCell className="TableField">{ user.email }</TableCell>
                            <TableCell className="TableField">{ user.user_profile }</TableCell>
                            <TableCell className="TableField">{ user.active ? "Sim" : "Não" }</TableCell>
                            <Link to={`/users/${user.id_user}`}>
                              <Button className='OpenOrderBtn' icon={ArrowUpRightIcon} />
                            </Link>
                        </TableRow>
                    )) }
            </TableBody>
        </Table>
    </div>
  );
}