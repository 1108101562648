import React, { useEffect, useState} from 'react';
import './OperationsPage.css';
import { 
  Card,
  Col,
  Grid,
  Title,
  TextInput,
  Dropdown,
  DropdownItem,
  Text,
  Divider,
  Metric,
  Button,
  DateRangePicker,
  DateRangePickerValue,
  Flex,
} from "@tremor/react";
import SidebarComponent from '../components/SidebarComponent';
import TitleComponent from '../components/TitleComponent';
import OperationsTableComponent from '../components/OperationsTableComponent';
import { ShortOrder } from '../interfaces/OrderInterfaces';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Drivers } from '../interfaces/AccessInterfaces';
import { ptBR } from "date-fns/locale";

export default function OperationsPage({drivers}:{drivers:Drivers}) {
  const [cookies] = useCookies(['mazelog-user']);
  const token = cookies['mazelog-user']?.token_data?.access_token;
  const oauth = "Bearer " + token;

  const now = new Date();

  const [requested, setRequested] = useState<boolean>(false);
  const [orders, setOrders] = useState<ShortOrder[]>([]);
  const [countOrders, setCountOrders] = useState<number>(0);
  const [expansions, setExpansions] = useState<number>(0);
  const [moreOrdersToShow, setMoreOrdersToShow] = useState<boolean>(true);
  const [selectedNumber, setSelectedNumber] = useState<string>('');
  const [selectedReceipt, setSelectedReceipt] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('all');
  const [selectedSender, setSelectedSender] = useState<string>('');
  const [selectedReceiver, setSelectedReceiver] = useState<string>('');
  const [selectedTransporter, setSelectedTransporter] = useState<string>('all');
  const [selectedDriver, setSelectedDriver] = useState<number>();
  const [wrongDriverId, setWrongDriverId] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangePickerValue>([
    new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
    now
  ]);
  const [dateDisabled, setDateDisabled] = useState<boolean>(true);

  const handleSubmit = (expansions: number) => {
    const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;
    let URL = `${baseURL}/order/operations?expansions=${expansions}`;
    if(selectedNumber !== ''){URL += `&order_number=${selectedNumber}`}
    if(selectedReceipt !== ''){URL += `&receipt_number=${selectedReceipt}`}
    if(selectedStatus !== 'all'){URL += `&status=${selectedStatus}`}
    if(selectedSender !== ''){URL += `&sender_document=${selectedSender}`}
    if(selectedReceiver !== ''){URL += `&receiver_document=${selectedReceiver}`}
    if(selectedTransporter !== 'all'){URL+= `&transporter=${selectedTransporter}`}
    if(selectedDriver){URL += `&courier_id_user=${selectedDriver}`}
    if(!dateDisabled){URL += `&emission_start_date=${selectedDateRange[0]?.toISOString()}&emission_end_date=${selectedDateRange[1]?.toISOString()}`}
    axios.get(URL,
    {headers: {'accept': 'application/json',"Authorization": oauth}})
    .then((res)=>{
      setOrders(res.data.orders);
      if (res.data.orders.length == orders.length && expansions > 0) {
        setMoreOrdersToShow(false);
      }
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  console.log(expansions);

  const expandSearch = () => {
    handleSubmit(expansions + 1);
    setExpansions(expansions => expansions + 1);
  }

  const handleFilter = () => {
    setMoreOrdersToShow(true);
    handleSubmit(0);
    setExpansions(0);
  }

  const driverFilterHandler = (email: string) => {
    const id_user = Object.keys(drivers).find((key:any) => drivers[key].email === email);
    if (id_user) {
      setWrongDriverId(false);
      setSelectedDriver(parseInt(id_user));
    } else {
      setSelectedDriver(undefined);
      setWrongDriverId(true);
    }
  }

  useEffect(()=>{
    if (!requested) {
      handleSubmit(expansions);
      setRequested(false);
    }
  },[requested])

  useEffect(() => {
    setCountOrders(orders.length);
  }, [orders])

  return (
    <main className="Page">
    <SidebarComponent />
    <div className="Content">
      <TitleComponent />
      <div className="OpsViews">
        <Card className="mt-6">
          <Grid numCols={2} className="gap-2">
            <Col numColSpan={1}>
              <Title>ID do pedido</Title>
              <TextInput className='max-w-md' onChange={(e) => setSelectedNumber(e.target.value)}/>
            </Col>
            <Col numColSpan={1}>
              <Title>Número da nota</Title>
              <TextInput
                placeholder='0000000000'
                className='max-w-md'
                onChange={(e) => setSelectedReceipt(e.target.value)}
              />
            </Col>
            <Col numColSpan={1}>
            <Title>Status</Title>
            <Dropdown
                  className='max-w-md'
                  defaultValue="all"
                  onValueChange={ (value) => setSelectedStatus(value) }
              >
                  <DropdownItem value="all" text="Todos os status" />
                  <DropdownItem value="created" text="Criado" />
                  <DropdownItem value="transporter_allocated" text="Transportador alocado" />
                  <DropdownItem value="collected" text="Coletado" />
                  <DropdownItem value="received" text="Recebido" />
                  <DropdownItem value="en_route" text="Em rota" />
                  <DropdownItem value="delivered" text="Entregue" />
                  <DropdownItem value="cancelled" text="Cancelado" />
                  <DropdownItem value="handling" text="Em tratativa" />
                  <DropdownItem value="awaiting_return" text="Aguard. devolução" />
                  <DropdownItem value="awaiting_transfer" text="Aguard. transferência" />
                  <DropdownItem value="return_route" text="Rota devolução" />
                  <DropdownItem value="returned" text="Devolvido" />
                  <DropdownItem value="collected_not_received" text="Coletados Não Receb." />
                  <DropdownItem value="cancelled_after_received" text="Canc. após Receb." />
                  <DropdownItem className='DropTransOption' value="returned_area_not_serviced" text="Devolvido - Área não atendida" />
                  <DropdownItem className='DropTransOption' value="new_driver_allocated" text="Novo entregador alocado" />
              </Dropdown>
            </Col>
            <Col numColSpan={1}>
              <Title>CNPJ remetente</Title>
              <TextInput placeholder="00.000.000/0000-00" className='max-w-md' onChange={(e) => setSelectedSender(e.target.value)}/>
            </Col>
            <Col numColSpan={1}>
              <Title>CNPJ/CPF destinatário</Title>
              <TextInput placeholder="00.000.000/0000-00" className='max-w-md' onChange={(e) => setSelectedReceiver(e.target.value)}/>                  
            </Col>
            <Col numColSpan={1}>
              <Title>Email entregador</Title>
              <TextInput className='max-w-md' error={wrongDriverId} onChange={(e) => driverFilterHandler(e.target.value)}/>
            </Col>
            <Col numColSpan={1}>
              <Title>Transportadora</Title>
              <Dropdown
                  className='max-w-md'
                  defaultValue="all"
                  onValueChange={(value) => setSelectedTransporter(value)}
              >
                  <DropdownItem value="all" text="Todas as transportadoras" />
                  <DropdownItem value="none" text="N/A" />
                  <DropdownItem value="flashExpress" text="Flash Express" />
                  <DropdownItem value="arrFlex" text="ARR Flex" />
              </Dropdown>
            </Col>
            <Col numColSpan={1}>
              <Title>Data de criação</Title>
              <DateRangePicker
                className="max-w-md"
                value={selectedDateRange}
                onValueChange={setSelectedDateRange}
                locale={ptBR}
                disabled={dateDisabled}
                dropdownPlaceholder="Selecionar"
                enableYearPagination={true}
              />
            </Col>
            <Col numColSpan={1}>
              <Flex justifyContent='start'>
                <Button className='FilterButton' onClick={handleFilter}>
                  Filtrar
                </Button>
                <Button
                  className='DateDisabledButton'
                  onClick={() => setDateDisabled(dateDisabled => !dateDisabled)}
                >
                  Ativar Filtro Data
                </Button>
              </Flex>
            </Col>
          </Grid>
          <div>
          <Divider/>
            <div>
              <Text>Pedidos com transportadora alocada</Text>
              <Metric>{moreOrdersToShow ? countOrders + '+' : countOrders}</Metric>
            </div>
            <OperationsTableComponent selectedOrders={orders}/>
          </div>
        {moreOrdersToShow ?
          <div>
            <Button
              onClick={expandSearch}
              className='FilterButton'
            >
              Carregar mais
            </Button>
          </div> : ""}
        </Card>
      </div>
    </div>
  </main>
  );
}