import React, { useState} from 'react';
import './OperationsTableComponent.css';
import { Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell, Dropdown, Button, DropdownItem } from "@tremor/react";
import { ShortOrder } from '../interfaces/OrderInterfaces'
import axios from 'axios';
import { useCookies } from 'react-cookie';

function OperationsTableComponent(
  {selectedOrders}:{selectedOrders:ShortOrder[]}
  ) {

    const [cookies] = useCookies(['mazelog-user']);
    const token = cookies['mazelog-user']?.token_data?.access_token;
    const oauth = "Bearer " + token;
    const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;

    const statusTranslate: { [key: string]: string } = {
      "created": "Criado",
      "transporter_allocated": "Transportador alocado",
      "collected": "Coletado",
      "received": "Recebido",
      "en_route": "Em rota",
      "delivered": "Entregue",
      "cancelled": "Cancelado",
      "handling": "Em tratativa",
      "awaiting_return": "Aguard. devolução",
      "awaiting_transfer": "Aguard. transferência",
      "return_route": "Rota devolução",
      "returned": "Devolvido",
      "collected_not_received": "Coletados Não Receb.",
      "cancelled_after_received": "Canc. após Receb.",
      "returned_area_not_serviced": "Devolvido - Área não atendida",
      "new_driver_allocated": "Novo entregador alocado"
    }

  const [selectedStatus, setSelectedStatus] = useState<string>('');

  const changeOrderStatus = (orderId:string) => {
    axios.put(`${baseURL}/order/${orderId}/status`,
    {status:
      selectedStatus,
      observation:'N/A',
      collecter:{
        name:'Entregador',document:'00000000000'
      }
    },
    {headers:
      {'accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization": oauth}
    })
      .then((res)=>{
      console.log(res);
      })
      .catch((err)=>{
      console.log(err);
      alert('Pedido já possui esse status associado')
    })
  }

  const convertDate = (date:Date) => {
    let newDate = new Date(date);
    newDate.setHours(newDate.getHours() - 3);
    let newDateStr = newDate.toLocaleString('en-GB').substring(0, 10);
    return newDateStr
  }

  const truncateString = (text:string, maxLength: number):string => {
    if (text.length <= maxLength) {
      return text
    } else {
      return text.substring(0, maxLength) + '...'
    }
  }

  return (
    <main className="Page">
              <Table className='TableCls'>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>ID do pedido</TableHeaderCell>
                        <TableHeaderCell className="text-left">Número da nota</TableHeaderCell>
                        <TableHeaderCell className="text-left">Data criação</TableHeaderCell>
                        <TableHeaderCell className="text-left">Status</TableHeaderCell>
                        <TableHeaderCell className="text-left">Remetente</TableHeaderCell>
                        <TableHeaderCell className="text-left">Destinatário</TableHeaderCell>
                        <TableHeaderCell className="text-left">CEP Dest</TableHeaderCell>
                        <TableHeaderCell className="text-left">Transportador</TableHeaderCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    { selectedOrders
                        .map((order) => (
                            <TableRow key={ order.orderNumber }>
                              <TableCell className="TableField">{ order.orderNumber }</TableCell>
                              <TableCell className="TableField">{ order.receiptNumber }</TableCell>
                              <TableCell className="TableField">{ convertDate(order.creationDate) }</TableCell>
                              <TableCell className="TableField">{ order.status !== 'created' ? (
                                <Dropdown
                                  className='DropTrans'
                                  defaultValue={order.status}
                                  onValueChange={(value) => setSelectedStatus(value)}
                              >
                                  <DropdownItem className='DropTransOption' value="collected" text="Coletado" />
                                  <DropdownItem className='DropTransOption' value="received" text="Recebido" />
                                  <DropdownItem className='DropTransOption' value="en_route" text="Em rota" />
                                  <DropdownItem className='DropTransOption' value="delivered" text="Entregue" />
                                  <DropdownItem className='DropTransOption' value="cancelled" text="Cancelado" />
                                  <DropdownItem className='DropTransOption' value="handling" text="Em tratativa" />
                                  <DropdownItem className='DropTransOption' value="awaiting_return" text="Aguard. devolução" />
                                  <DropdownItem className='DropTransOption' value="awaiting_transfer" text="Aguard. transferência" />
                                  <DropdownItem className='DropTransOption' value="return_route" text="Rota devolução" />
                                  <DropdownItem className='DropTransOption' value="returned" text="Devolvido" />
                                  <DropdownItem className='DropTransOption' value="collected_not_received" text="Coletados Não Receb." />
                                  <DropdownItem className='DropTransOption' value="cancelled_after_received" text="Canc. após Receb." />
                                  <DropdownItem className='DropTransOption' value="returned_area_not_serviced" text="Devolvido - Área não atendida" />
                                  <DropdownItem className='DropTransOption' value="new_driver_allocated" text="Novo entregador alocado" />
                              </Dropdown> ): (
                                statusTranslate[order.status]
                              )}</TableCell>
                              <TableCell className="TableField">{ truncateString(order.sender, 10) }</TableCell>
                              <TableCell className="TableField">{ truncateString(order.receiver, 25) }</TableCell>
                              <TableCell className="TableField">{ order.receiverZIP }</TableCell>
                              <TableCell className="TableField">{ order.transporter }</TableCell>
                              <Button onClick={() => changeOrderStatus(order.orderNumber)} className='OpenOrderBtn' />
                            </TableRow>
                        )) }
                </TableBody>
            </Table>
  </main>
  );
}

export default OperationsTableComponent;