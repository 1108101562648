import React, { useEffect, useState } from 'react';
import './OccurrenceChatPage.css';
import { useParams, useNavigate } from 'react-router-dom';
import SidebarComponent from '../../components/SidebarComponent';
import TitleComponent from '../../components/TitleComponent';
import {
  Card,
  TextInput,
  Button,
  Flex,
  Callout,
} from "@tremor/react";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { OrderOccurrence, OccurrenceStatus } from '../../interfaces/OccurrenceInterfaces';
import OccurrenceMessageComponent from '../../components/occurrenceComponents/OccurrenceMessageComponent';

export default function OccurrenceChatPage() {
  const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;
  let { id } = useParams();
  const [orderOccurrence, setOrderOccurrence] = useState<OrderOccurrence>();
  const [buttonsDisabeled, setButtonsDisabeled] = useState<boolean>(true);
  const [orderUpdated, setOrderUpdated] = useState<boolean>(false);
  const [messageContent, setMessageContent] = useState<string>();

  const [cookies] = useCookies(['mazelog-user']);
  const token = cookies['mazelog-user']?.token_data?.access_token;
  const oauth = "Bearer " + token;
  const viewerIdOrg = cookies['mazelog-user']?.organization_data?.id_org;

  const resolveOccurrence = () => {
    if (window.confirm("Deseja mesmo concluir esta ocorrência?")){
      axios.post(`${baseURL}/order/occurrences/${id}/resolve`,{},{headers: {"Authorization": oauth}})
      .then((res) => {
          console.log(res);
          setOrderUpdated(!orderUpdated);
      })
      .catch((err) => {
          console.log(err);
      })
    }
  }

  const handleKeypress = (e:any) => {
    if (e.keyCode === 13) {
      sendMessage();
    }
  };

  const cancelOccurrence = () => {
    if (window.confirm("Deseja mesmo cancelar esta ocorrência?")){
      axios.delete(`${baseURL}/order/occurrences/${id}`,{headers: {"Authorization": oauth}})
      .then((res) => {
          console.log(res);
          setOrderUpdated(!orderUpdated);
      })
      .catch((err) => {
          console.log(err);
      })
    }
  }

  const getOccurrenceData = () => {
    axios.get(`${baseURL}/order/occurrences/${id}`,{headers: {"Authorization": oauth}})
      .then((res) => {
          console.log(res);
          setOrderOccurrence(res.data);
          setButtonsDisabeled(res.data?.status !== OccurrenceStatus.OPEN);
      })
      .catch((err) => {
          console.log(err);
      })
  }

  const sendMessage = () => {
    axios.post(`${baseURL}/order/occurrences/${id}`,
      {content: messageContent},
      {headers: {"Authorization": oauth}})
    .then((res) => {
        console.log(res);
        setOrderUpdated(!orderUpdated);
    })
    .catch((err) => {
        console.log(err);
    })
  }

  useEffect(()=>{
    getOccurrenceData();
  },[id, orderUpdated])

  return (
    <main className="Page">
      <SidebarComponent />
      <div className="Content">
        <TitleComponent />
        <Flex justifyContent='end'>
          <Button
            disabled={buttonsDisabeled}
            onClick={resolveOccurrence}
            className='DownloadBtn'>Concluir ocorrência</Button>
          <Button
            disabled={buttonsDisabeled}
            onClick={cancelOccurrence}
            className='DownloadBtn'>Cancelar ocorrência</Button>
        </Flex>
        <div className="DashboardViews">
          <Card>
          {orderOccurrence?.status === OccurrenceStatus.OPEN ?
            <Callout title='Status' color='yellow'>Ocorrência em aberto</Callout> :
            ""
          }
          {orderOccurrence?.status === OccurrenceStatus.CANCELLED ?
            <Callout title='Status' color='rose'>Ocorrência cancelada</Callout> :
            ""
          }
          {orderOccurrence?.status === OccurrenceStatus.SOLVED ?
            <Callout title='Status' color='teal'>Ocorrência concluída</Callout> :
            ""
          }
            <Card className='MessageBackground'>
              <Flex flexDirection='col'>
              { !!orderOccurrence ?
               orderOccurrence.messages
                .map((message) => (
                    <OccurrenceMessageComponent
                      key={orderOccurrence.messages.findIndex(i => i === message)}
                      message={message} viewerIdOrg={viewerIdOrg}/>
                )) :
                "" }
              </Flex>
            </Card>
            <Flex justifyContent="between" className='mt-2.5'>
              <TextInput
                className='mt-2.5 mr-1'
                placeholder='Digite sua resposta...'
                onChange={(e) => setMessageContent(e.target.value)}
                onKeyDown={handleKeypress}
              />
              <Button
                disabled={!!orderOccurrence && buttonsDisabeled}
                onClick={sendMessage}
                className='SendMessageBtn'
              >Enviar mensagem</Button>
            </Flex>
          </Card>
        </div>
      </div>
    </main>
  );
}