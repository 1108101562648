import React, { useEffect, useState } from 'react';
import './StickerPage.css';
import SidebarComponent from '../components/SidebarComponent';
import TitleComponent from "../components/TitleComponent";
import PDFStickerComponent from '../components/stickerComponents/MazelogStickerComponent';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate, Link } from 'react-router-dom';
import { FullOrder } from '../interfaces/OrderInterfaces';
import { Button, Flex } from '@tremor/react';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';


export default function StickerPage(
    {orderIds}:{orderIds:string[]}
    ) {
    const [orders, setOrders] = useState<FullOrder[]>();
    const [cookies] = useCookies(['mazelog-user']);
    const productFlag = cookies['mazelog-user']?.organization_data?.product_flag
    const token = cookies['mazelog-user']?.token_data?.access_token
    const oauth = "Bearer " + token;
    const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;

    const navigate = useNavigate();
    const getMazelogStickerData = () => {
        axios.post(`${baseURL}/order/batch`,
                    {data:orderIds},
                    {headers: {"Authorization": oauth}})
        .then((res) => {
            console.log(res);
            setOrders(res.data.results);
        })
        .catch((err) => {
            console.log(err);
            navigate(`/search`);
        })
    }
    
    useEffect(()=>{
        getMazelogStickerData();
    },[orderIds])

  return (
    <main>
        <SidebarComponent />
        <div className='OrderContent'>
            <TitleComponent />
            {orders ? (
                <Flex justifyContent='end'>
                    <Button className='DownloadBtn'>
                        <PDFDownloadLink
                            document={
                                <PDFStickerComponent orderList={orders} productFlag={productFlag} />
                            }
                            fileName={`${new Date()}.pdf`}
                            >
                        Baixar etiqueta
                        </PDFDownloadLink>
                    </Button>
                </Flex>
            ) : (
                <Flex justifyContent='end'>
                    <Button className='DownloadBtn' disabled={true}>Baixar etiqueta</Button>
                </Flex>
            )}
            <div className="DashboardViews">
                {orders ?
                    <PDFViewer className='PDFCard' showToolbar={false}>
                        <PDFStickerComponent orderList={orders} productFlag={productFlag} />
                    </PDFViewer> :
                ""}
            </div>
        </div>
    </main>
  );
}