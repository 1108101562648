import React, { useState } from 'react';
import './LoginPage.css';
import { TextInput, Button, Text } from '@tremor/react';
import { useCookies } from 'react-cookie';
import { UserIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import axios from 'axios';

export default function LoginPage() {
  const [cookies, setCookie] = useCookies(['mazelog-user']);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [wrongCredentials, setWrongCredentials] = useState<boolean>(false);
  const [isRequestProcessing, setIsRequestProcessing] = useState<boolean>(false);

  const loginHandler = async () => {
    setIsRequestProcessing(true);
    verifyLogin()
    .then((res) => {
      setIsRequestProcessing(false);
      if (res?.data?.organization_data?.profile === 'CORP') {
        setCookie(
          "mazelog-user",
          JSON.stringify(res?.data),
          {path: "/", maxAge: 3600*8*5}
        );
      } else {
        setWrongCredentials(true);
      }
    }).catch((res)=>{
      console.log(res);
      setIsRequestProcessing(false);
      setWrongCredentials(true);
    })
  }

  const verifyLogin = async () => {
    let form = new FormData();
    form.append('username', username);
    form.append('password', password);
    const baseURL = process.env.REACT_APP_MAZELOG_AUTH_BASE_URL;
    const response = axios.post(`${baseURL}/token`,
      form,
      {headers: { "Content-Type": "application/x-www-form-urlencoded"}}
    )

    return response
  }

  const handleKeypress = (e:any) => {
    if (e.keyCode === 13) {
      loginHandler();
    }
  };

  return (
    <div className="LoginPage">
        <div className='LoginForm'>
          <img className='LoginLogo' src={require('../images/mzlog 1.png')} alt='mazelog logo' />
          <Text className='LoginInstruction'>Email Address</Text>
          <TextInput
            icon={UserIcon}
            placeholder=''
            className='LoginUserInput'
            onChange={(e) => setUsername(e.target.value)}
            error={wrongCredentials}
            onKeyDown={handleKeypress}
          />
          <Text className='LoginInstruction'>Password</Text>
          <TextInput
            type='password'
            icon={LockClosedIcon}
            placeholder=''
            className='LoginPasswdInput'
            onChange={(e) => setPassword(e.target.value)}
            error={wrongCredentials}
            onKeyDown={handleKeypress}
          />
          <Button className='LoginButton' onClick={() => loginHandler()} disabled={isRequestProcessing}>Login</Button>
        </div>
    </div>
  );
}