
import { useState, useEffect } from "react";
import {
  Card,
  DateRangePicker,
  DateRangePickerValue,
  BarChart,
  Button,
  Grid,
  Col,
  Metric
} from "@tremor/react";
import './DashboardPage.css';
import SidebarComponent from '../components/SidebarComponent';
import TitleComponent from "../components/TitleComponent";
import { ptBR } from "date-fns/locale";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { CountDailyOrders } from "../interfaces/MetricsInterfaces";


export default function DashboardPage() {
  const [cookies] = useCookies(['mazelog-user']);
  const token = cookies['mazelog-user']?.token_data?.access_token;
  const oauth = "Bearer " + token;

  const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;

  const [requested, setRequested] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangePickerValue>([
    new Date(),
    new Date()
  ]);
  const [countDailyOrders, setCountDailyOrders] = useState<CountDailyOrders[]>()

  const handleSubmit = () => {
    let URL = `${baseURL}/metrics/daily_orders?start_date=${selectedDateRange[0]?.toISOString().split('T')[0]}&end_date=${selectedDateRange[1]?.toISOString().split('T')[0]}`
    axios.get(URL,{headers: {accept: 'application/json', "Authorization": oauth}})
    .then((res)=>{
      console.log(res);
      setCountDailyOrders(res.data.results);
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  useEffect(()=>{
    if (!requested){
      handleSubmit();
      setRequested(true);
    }
  },[requested])

  return (
    <main className="Page">
      <SidebarComponent />
      <div className="Content">
        <TitleComponent />
        <div className="DashboardViews">
          <Card>
            <Grid numCols={2} className="gap-2">
              <Col numColSpan={1}>
                <DateRangePicker
                  className="max-w-md"
                  value={selectedDateRange}
                  onValueChange={setSelectedDateRange}
                  locale={ptBR}
                  dropdownPlaceholder="Selecionar"
                  enableYearPagination={true}
                />
              </Col>
              <Col numColSpan={1}>
                <Button className='FilterButton' onClick={() => handleSubmit()}>
                  Filtrar
                </Button>
              </Col>
            </Grid>
            {!countDailyOrders ? "" :
            <div>
              <Metric>Número de pedidos diários</Metric>
              <BarChart
              data={countDailyOrders}
              index="date"
              categories={['count']}
              colors={['purple']}
              yAxisWidth={48}/>
            </div>
            }
          </Card>
        </div>
      </div>
    </main>
  );
}