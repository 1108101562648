import React, { useEffect, useState } from 'react';
import './OrganizationListComponent.css';
import {
    Table,
    TableHead,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    Flex,
    Button,
  } from "@tremor/react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Organization } from '../../interfaces/AccessInterfaces';

export default function OrganizationListComponent() {
    const [cookies] = useCookies(['mazelog-user']);
    const token = cookies['mazelog-user']?.token_data?.access_token;
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [requested, setRequested] = useState<boolean>(false);

    const handleSubmit = () => {
        const baseURL = process.env.REACT_APP_MAZELOG_AUTH_BASE_URL;
        const oauth = "Bearer " + token;
        axios.get(`${baseURL}/organizations`,
          {headers: {accept: 'application/json', "Authorization": oauth}}
        )
        .then((res)=>{
          console.log(res);
          setOrganizations(res.data.data);
        })
        .catch((err)=>{
          console.log(err);
        })
    }

    useEffect(()=>{
        if (!requested){
          handleSubmit();
          setRequested(true);
        }
      },[requested])

    const treatBool = (boolValue:boolean) : string => {
        if (boolValue) {
            return "Sim"
        } else {
            return "Não"
        }
    }

  return (
    <div>
        <div className='ButtonBlock'>
            <Flex justifyContent='end'>
                <Link to={`/organizations/create`}>
                    <Button className='DownloadBtn'>Criar organização</Button>
                </Link>
            </Flex>
        </div>
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>ID organização</TableHeaderCell>
                    <TableHeaderCell className="text-left">CNPJ</TableHeaderCell>
                    <TableHeaderCell className="text-left">Nome</TableHeaderCell>
                    <TableHeaderCell className="text-left">Perfil</TableHeaderCell>
                    <TableHeaderCell className="text-left">Criação via planilha</TableHeaderCell>
                    <TableHeaderCell className="text-left">Envio Correios</TableHeaderCell>
                    <TableHeaderCell className="text-left">Produto na etiqueta</TableHeaderCell>
                    <TableHeaderCell className="text-left">Disparo WhatsApp</TableHeaderCell>
                    <TableHeaderCell className="text-left">Disparo Email</TableHeaderCell>
                </TableRow>
            </TableHead>

            <TableBody>
                { organizations
                    .map((organization) => (
                        <TableRow key={ organization.id_org }>
                            <TableCell className="TableField">{ organization.id_org }</TableCell>
                            <TableCell className="TableField">{ organization.cnpj }</TableCell>
                            <TableCell className="TableField">{ organization.name }</TableCell>
                            <TableCell className="TableField">{ organization.profile }</TableCell>
                            <TableCell className="TableField">{ treatBool(organization.spreadsheet) }</TableCell>
                            <TableCell className="TableField">{ treatBool(organization.correios) }</TableCell>
                            <TableCell className="TableField">{ treatBool(organization.product_flag) }</TableCell>
                            <TableCell className="TableField">{ treatBool(organization.whatsapp_flag) }</TableCell>
                            <TableCell className="TableField">{ treatBool(organization.email_flag) }</TableCell>
                            {/*<Link to={`/users/${user.id_user}`}>
                            <Button className='OpenOrderBtn' icon={ArrowUpRightIcon} />
                    </Link>*/}
                        </TableRow>
                    )) }
            </TableBody>
        </Table>
    </div>
  );
}